import React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"

import Layout from "../components/layout"
import Seo from "../components/seo"

const SightseeingTemplate = ({ data: { sightseeing } }) => {
  const featuredImage = getImage(sightseeing.featuredImage.node.localFile)

  return (
    <Layout isHomePage={true} headerClassName="bg-white" isLogoOneWhite={false} isLogoTwoWhite={false}>
      <Seo title={sightseeing.title} description={sightseeing.excerpt} />
      <main id="sightseeing-detail">
        <h2 id="sightseeing-ttl"><StaticImage src="../images/ttl-sightseeing@2x.png" alt="ニュース" /></h2>
        <article
          className="inner"
          itemScope
          itemType="http://schema.org/Article"
        >
          <header>
            <GatsbyImage image={featuredImage} alt={sightseeing.title} />
            <h1 itemProp="headline">{parse(sightseeing.title)}</h1>
            <p className="date">{sightseeing.date}</p>
          </header>
          {!!sightseeing.content && (<section itemProp="articleBody" className="sightseeing-body">{parse(sightseeing.content)}</section>)}
        </article>
        <Link className="link-btn" to="/sightseeing"><span>グルメ＆スポット記事一覧へ</span></Link>
      </main>
    </Layout>
  )
}

export default SightseeingTemplate

export const pageQuery = graphql`
  query SightseeingById(
    # these variables are passed in via createPage.pageContext in gatsby-node.js
    $id: String!
  ) {
    # selecting the current sightseeing by id
    sightseeing: wpSightseeing(id: { eq: $id }) {
      id
      content
      title
      date(formatString: "MMMM DD, YYYY")
      featuredImage {
        node {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(
                placeholder: BLURRED
                formats: [AUTO]
              )
            }
          }
        }
      }
    }
  }
`
